import Repository from "./Repository";

const resource = "/admin/api/currency";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },
}