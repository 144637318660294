import Repository from "./Repository";

const resource = "/admin/api/cache";

export default {
    clear(type) {
        let payload = new FormData();
        payload.append('type', type);

        return Repository.post(`${resource}/clear`, payload);
    },

    updateBackend(type, categoryId, locale) {
        let payload = new FormData();
        payload.append('type', type);
        payload.append('category_id', categoryId);
        payload.append('locale', locale);

        return Repository.post(`${resource}/update/backend`, payload);
    },

    updateFrontend(productIds) {
        let payload = new FormData();
        payload.append('product_ids', JSON.stringify(productIds));

        return Repository.post(`${resource}/update/frontend`, payload);
    }
}