import Repository from "./Repository";

const resource = "/admin/api/user";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    logged() {
        return Repository.post(`${resource}/logged`);
    },

    // loggedSettings() {
    //     return Repository.post(`${resource}/logged/settings`);
    // },
}