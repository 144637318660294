import Repository from "./Repository";

const resource = "/admin/api/issue";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    actions(type) {
        let payload = new FormData();
        payload.append('type', type);

        return Repository.post(`${resource}/actions`, payload);
    },
}