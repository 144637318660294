import Repository from "./Repository";

const resource = "/api/package";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/save`, payload);
    },

    select(addressId, companyId) {
        let payload = new FormData();
        payload.append('address', addressId);
        payload.append('company', companyId);

        return Repository.post(`${resource}/select`, payload);
    },
}