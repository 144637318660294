import Repository from "./Repository";

const resource = "/api/claim";

export default {
    create(purchase, documentNumber, note, type) {
        let payload = new FormData;
        payload.append('purchase', purchase);
        payload.append('document_number', documentNumber);
        payload.append('type', type);

        if(!_.isNil(note)) {
            payload.append('note', note);
        }

        return Repository.post(`${resource}/create`, payload)
    },

    getType(type) {
        return Repository.get(`${resource}/type/${type}`)
    },
}