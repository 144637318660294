import Repository from "./Repository";

const resource = "/api/price-tracker";

export default {
    create(productId, url, email, price) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('url', url);
        payload.append('email', email);
        payload.append('price', price);

        return Repository.post(`${resource}`, payload);
    },
}