import Repository from "./Repository";

const resource = "/admin/api/accounting/record";

export default {
    getAll() {
        return Repository.get(`${resource}`);
    },

    getBetween(start, end) {
        let payload = new FormData;
        payload.append('start', JSON.stringify(start));
        payload.append('end', JSON.stringify(end));

        return Repository.post(`${resource}/between`, payload);
    },

    getOne(id) {
        return Repository.get(`${resource}/${id}`);
    },

    save(accountingRecord) {
        let payload = new FormData;
        payload.append('accounting_record', JSON.stringify(accountingRecord));

        return Repository.post(`${resource}/save`, payload);
    },

    duplicate(accountingRecordId) {
        let payload = new FormData;
        payload.append('accounting_record_id', JSON.stringify(accountingRecordId));

        return Repository.post(`${resource}/duplicate`, payload);
    },

    getItem(accountingRecordItemId) {
        return Repository.get(`${resource}/item/${accountingRecordItemId}`);
    },

    getAllItems() {
        return Repository.get(`${resource}/item`);
    },

    getItemsByParent(ids) {
        let payload = new FormData;
        payload.append('ids', JSON.stringify(ids));

        return Repository.post(`${resource}/items`, payload);
    },

    getItems(accountingRecordId) {
        return Repository.get(`${resource}/${accountingRecordId}/item`);
    },

    getFiles(accountingRecordId) {
        return Repository.get(`${resource}/${accountingRecordId}/file`);
    },

    getPayments(accountingRecordId) {
        return Repository.get(`${resource}/${accountingRecordId}/payment`);
    },

    getCategories() {
        return Repository.get(`${resource}/category`);
    },

    getDomains() {
        return Repository.get(`${resource}/domain`);
    },

    getTags() {
        return Repository.get(`${resource}/tag`);
    },

    sepa(id) {
        return Repository.get(`${resource}/sepa/${id}`);
    },

    fileRemove(id) {
        return Repository.get(`${resource}/file/${id}/remove`);
    },
}