import Repository from "./Repository";

const resource = "/admin/api/warehouse";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    start(purchaseId) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchaseId));

        return Repository.post(`${resource}/start`, payload);
    },

    finish(purchases, purchaseItems, dateFrom, dateTo, force, message, unselectedPurchaseIds) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));
        payload.append('purchase_items', JSON.stringify(purchaseItems));
        payload.append('date_from', dateFrom);
        payload.append('date_to', dateTo);
        payload.append('force', JSON.stringify(force));
        payload.append('purchases_uselected', JSON.stringify(unselectedPurchaseIds));

        if(message) {
            payload.append('message', JSON.stringify(message));
        }

        return Repository.post(`${resource}/finish`, payload);
    },

    log(purchaseId, productId, stockMovementItemId, stockrackName, dateFrom, dateTo) {
        let payload = new FormData();
        payload.append('purchase_id', purchaseId);
        payload.append('product_id', productId);
        payload.append('stock_movement_item_id', stockMovementItemId);
        payload.append('stockrack_name', stockrackName);
        payload.append('date_from', dateFrom);
        payload.append('date_to', dateTo);

        return Repository.post(`${resource}/log`, payload);
    },

    findItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`${resource}/items`, payload);
    },

    findItemsNew(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`${resource}/items-new`, payload);
    },

    findSetItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`${resource}/set-items`, payload);
    },

    findGiftItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`${resource}/gift-items`, payload);
    },

    findPurchases(prepared) {
        if(!_.isNil(prepared)) {
            let payload = new FormData();
            payload.append('prepared', JSON.stringify(prepared));
        }

        return Repository.post(`${resource}/purchases`);
    },

    findPurchasesNew(prepared) {
        if(!_.isNil(prepared)) {
            let payload = new FormData();
            payload.append('prepared', JSON.stringify(prepared));
        }

        return Repository.post(`${resource}/purchases-new`);
    },

    findPurchasesByMode(mode) {
        let payload = new FormData();
        payload.append('mode', mode);

        return Repository.post(`${resource}/purchases-mode`, payload);
    },

    countPurchaseToday() {
        return Repository.get(`${resource}/purchase-count-today`);
    },

    countProductToday(productId) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));

        return Repository.post(`${resource}/product-count-today`, payload);
    },

    findCarts() {
        return Repository.get(`${resource}/carts`);
    },

    getPurchaseSimilarity(purchase_ids) {
        let payload = new FormData();
        payload.append('purchase_ids', purchase_ids);

        return Repository.post(`${resource}/purchases-similarity`, payload);
    },
}