import Repository from "./Repository";

const resource = "/admin/api/consumer-group-catalog";

export default {
    getAll() {
        return Repository.get(`${resource}`);
    },

    getOne(id) {
        return Repository.get(`${resource}/${id}`);
    },

    save(productId, price, type, consumerGroupId, categoryId) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));
        payload.append('price', JSON.stringify(price));
        payload.append('type', JSON.stringify(type));
        payload.append('consumer_group_id', JSON.stringify(consumerGroupId));
        payload.append('category_id', JSON.stringify(categoryId));

        return Repository.post(`${resource}`, payload)
    },
}