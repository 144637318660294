import Repository from "./Repository";

const resource = "/admin/api/export";

export default {
    export(type, data, title, filename) {
        switch (type) {
            case 'csv':
                return this.csv(data, title, filename);
            case 'html':
                return this.html(data, title, filename);
            case 'pdf':
                return this.pdf(data, title, filename);
            case 'txt':
                return this.txt(data, title, filename);
            case 'xlsx':
                return this.xlsx(data, title, filename);
        }
    },

    csv(data, title, filename) {
        let payload = new FormData();
        payload.append('data', JSON.stringify(data));
        payload.append('title', title);
        payload.append('filename', filename);

        return Repository.post(`${resource}/csv`, payload, {'responseType': 'blob'});
    },

    html(data, title, filename) {
        let payload = new FormData();
        payload.append('data', JSON.stringify(data));
        payload.append('title', title);
        payload.append('filename', filename);

        return Repository.post(`${resource}/html`, payload, {'responseType': 'blob'});
    },

    pdf(data, title, filename) {
        let payload = new FormData();
        payload.append('data', JSON.stringify(data));
        payload.append('title', title);
        payload.append('filename', filename);

        return Repository.post(`${resource}/pdf`, payload, {'responseType': 'blob'});
    },

    txt(data, title, filename) {
        let payload = new FormData();
        payload.append('data', JSON.stringify(data));
        payload.append('title', title);
        payload.append('filename', filename);

        return Repository.post(`${resource}/txt`, payload, {'responseType': 'blob'});
    },

    xlsx(data, title, filename) {
        let payload = new FormData();
        payload.append('data', JSON.stringify(data));
        payload.append('title', title);
        payload.append('filename', filename);

        return Repository.post(`${resource}/xlsx`, payload, {'responseType': 'blob'});
    },

    data(entity, target, ids) {
        let payload = new FormData();
        payload.append('entity', entity);
        payload.append('target', target);
        payload.append('ids', JSON.stringify(ids));

        return Repository.post(`${resource}/data`, payload);
    },
}