import Repository from "./Repository";

const resource = "/admin/api/product";

export default {
    findAll() {
        return Repository.get(`${resource}s`);
    },

    findBy(payload) {
        return Repository.post(`${resource}s`, payload);
    },

    findBy2(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    search(query) {
        let payload = new FormData();
        payload.append('query', query);
        payload.append('_elastic', JSON.stringify(false));

        return Repository.post(`${resource}/search`, payload);
    },

    getIdByCode(code, type) {
        let payload = new FormData();
        payload.append('code', code);
        payload.append('type', type);

        return Repository.post(`${resource}/id-by-code`, payload);
    },

    getWithPrice(id) {
        return Repository.post(`${resource}`, {'id': id});
    },

    printedExpiration() {
        return Repository.get(`${resource}/printed-expiration`);
    },

    getReserve(payload) {
        return Repository.post(`${resource}/reserve`, payload);
    },

    setReserve(payload) {
        Repository.post(`${resource}/reserve-save`, payload);
    },

    findWaiting(productIds) {
        let payload = new FormData();
        payload.append('product_id', productIds);

        return Repository.post(`${resource}/waiting`, payload);
    },

    quantity(productIds, reserved) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productIds));

        if(!_.isNil(reserved)) {
            payload.append('reserved', reserved);
        }

        return Repository.post(`${resource}/quantity`, payload);
    },

    // stock rack

    findStockRack(productIds, purchaseId) {
        let payload = new FormData();
        payload.append('product_id', productIds);
        payload.append('purchase_id', purchaseId);

        return Repository.post(`${resource}/stock-rack`, payload);
    },

    countStockRack(productIds, stockRackId) {
        let payload = new FormData();
        payload.append('product_id', productIds);
        payload.append('stock_rack_id', stockRackId);

        return Repository.post(`${resource}/stock-rack/count`, payload);
    },

    stockRackCapacity(productId, stockRackId) {
        let payload = new FormData();

        if(!_.isNil(productId)) {
            payload.append('product_id', productId);
        }

        if(!_.isNil(stockRackId)) {
            payload.append('stock_rack_id', stockRackId);
        }

        return Repository.post(`${resource}/stock-rack/capacity`, payload);
    },

    findUnassignedStockItemsByProduct(productId) {
        let payload = new FormData();

        if(!_.isNil(productId)) {
            payload.append('product_id', productId);
        }

        return Repository.post(`${resource}/unassigned-stock-movement-items`, payload);
    },

    saveStockRackCapacity(productId, stockRackId, capacity, print, psrBox, psrProduct) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('stock_rack_id', stockRackId);
        payload.append('capacity', capacity);
        payload.append('print', print);

        if(!_.isNil(psrBox)) {
            payload.append('psr_box', psrBox);
        }

        if(!_.isNil(psrProduct)) {
            payload.append('psr_product', psrProduct);
        }

        return Repository.post(`${resource}/stock-rack/capacity/save`, payload);
    },

    deleteStockRackCapacity(productId, stockRackId) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('stock_rack_id', stockRackId);

        return Repository.post(`${resource}/stock-rack/capacity/delete`, payload);
    },


    // category

    findCategories(productIds) {
        let payload = new FormData();
        payload.append('products', productIds);
        payload.append('type', 'relations');

        return Repository.post(`${resource}/category`, payload);
    },

    getMainCategory(productId) {
        let payload = new FormData();
        payload.append('product', productId);
        return Repository.post(`${resource}/category-main`, payload);
    },

    categoryChange() {

    },

    categoryAttach(productIds, mainCategory, categories, clear, main, locale, categoryFrom) {
        let payload = new FormData();
        payload.append('products', productIds);

        if(!_.isNil(mainCategory)) {
            payload.append('category', mainCategory);
        }

        payload.append('categories', categories);
        payload.append('_clear', clear);
        payload.append('_main', main);
        payload.append('locale', locale);

        if(!_.isNil(categoryFrom)) {
            payload.append('category_from', categoryFrom);
        }

        return Repository.post(`${resource}/category/attach`, payload);
    },

    categoryDetach(productIds, category) {
        let payload = new FormData();
        payload.append('products', productIds);
        payload.append('category', JSON.stringify(category));

        return Repository.post(`${resource}/category/detach`, payload);
    },


    // tag

    findTags(productIds) {
        let payload = new FormData();
        payload.append('type', 'relations');
        payload.append('products', _.join(productIds, ','));

        return Repository.post(`${resource}/tag`, payload);

        // return Repository.get(`${resource}/tag`, {
        //     params: {
        //         type: 'relations',
        //         products: _.join(productIds, ',')
        //     }
        // });
    },


    // image

    imageAttach(productId, title, text, sort, main) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('title', title);
        payload.append('text', text);
        payload.append('sort', sort);
        payload.append('main', main);

        return Repository.post(`${resource}/image/append`, payload);
    },

    imageUpload(file, productId, title, text, sort, main) {
        let payload = new FormData();
        payload.append('file', file);
        payload.append('product_id', productId);
        payload.append('title', title);
        payload.append('text', text);
        payload.append('sort', sort);
        payload.append('main', main);

        return Repository.post(`/admin/api/image/upload`, payload);
    },


    // filter

    filterChoices(categoryId) {
        let payload = new FormData();
        payload.append('category_id', categoryId);

        return Repository.post(`/api/filter`, payload);
    },

    filterCount(categoryId, filterData) {
        let payload = new FormData();
        payload.append('category', categoryId);
        payload.append('filter', JSON.stringify(filterData));
        payload.append('_json', true);

        return Repository.post(`/api/filter-count`, payload);
    },


    // other

    getPrice(productId, countryId, currencyId, userId, purchaseId, wholesale) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('country_id', countryId);
        payload.append('currency_id', currencyId);
        payload.append('user_id', userId);
        payload.append('purchase_id', purchaseId);
        payload.append('wholesale', wholesale);

        return Repository.post(`${resource}/price`, payload);
    },

    visibility(productsIds) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productsIds));

        return Repository.post(`${resource}/visibility`, payload);
    },

    neverAgain(productsIds) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productsIds));

        return Repository.post(`${resource}/neveragain`, payload);
    },

    setVisibility(productsIds, value) {
        let payload = new FormData();
        payload.append('product', productsIds);
        payload.append('visibility', value);

        return Repository.post(`${resource}/visibility-switch`, payload);
    },

    setNeverAgain(productsIds, value) {
        let payload = new FormData();
        payload.append('product', productsIds);
        payload.append('neveragain', value);

        return Repository.post(`${resource}/neveragain-switch`, payload);
    },

    setAdminDisable(productsIds) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productsIds));

        return Repository.post(`${resource}/admin-disable`, payload);
    },

    stockMovementItemHistory(productId) {
        let payload = new FormData();
        payload.append('product_id', productId);

        return Repository.post(`${resource}/stock-movement-item-history`, payload);
    },

    merchantability() {
        let payload = new FormData();
        payload.append('serialize_group', 'api_inventory');

        return Repository.post(`${resource}/merchantability`, payload);
    },

    merchantabilityDaily(productId, interval) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('interval', interval);

        return Repository.post(`${resource}/merchantability`, payload);
    },

    restock(productId, stockId, filterDate) {
        let payload = new FormData();

        if (productId) {
            payload.append('product_id', JSON.stringify(productId));
        }

        payload.append('stock_id', JSON.stringify(stockId));
        // payload.append('date', JSON.stringify(filterDate));

        return Repository.post(`${resource}/restock`, payload);
    },

    reserved(productId, purchaseId, excludePurchaseId) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));

        if(purchaseId) {
            payload.append('purchase_id', JSON.stringify(purchaseId));
        }

        if(excludePurchaseId) {
            payload.append('exclude_purchase_id', JSON.stringify(excludePurchaseId));
        }

        return Repository.post(`${resource}/reserved`, payload);
    },

    reduce(productsIds, stockId, primary, active, service) {
        let payload = new FormData();
        payload.append('product', JSON.stringify(productsIds));
        payload.append('stock', JSON.stringify(stockId));
        payload.append('primary', JSON.stringify(primary));
        payload.append('active', JSON.stringify(active));
        payload.append('service', JSON.stringify(service));

        return Repository.post(`${resource}/reduce`, payload);
    },

    weight(productsIds) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productsIds));

        return Repository.post(`${resource}/weight`, payload);
    },

    priceBuy(supplier, product, country) {
        let payload = new FormData();

        if (!_.isNil(supplier)) {
            payload.append('supplier', JSON.stringify(supplier));
        }

        if (!_.isNil(country)) {
            payload.append('country', JSON.stringify(country));
        }
        payload.append('product', JSON.stringify(product));

        return Repository.post(`${resource}/price-buy`, payload);
    },

    pricesBuy(product) {
        return Repository.get(`${resource}/prices-buy/${product}`);
    },

    priceSell(country, product) {
        let payload = new FormData();
        payload.append('country', JSON.stringify(country));
        payload.append('product', JSON.stringify(product));

        return Repository.post(`${resource}/price-sell`, payload);
    },

    stockOut(productsIds, filterDate) {
        let payload = new FormData();
        payload.append('product', JSON.stringify(productsIds));
        payload.append('date', JSON.stringify(filterDate));

        return Repository.post(`${resource}/stock-out`, payload);
    },

    actionTranslateDelegate(category) {
        let payload = new FormData();
        payload.append('category', JSON.stringify(category));
        return Repository.post(`${resource}/action/translate-delegate-find`, payload);
    },

    actionTranslateDelegateByProduct(productIds) {
        let payload = new FormData();
        payload.append('product', JSON.stringify(productIds));
        return Repository.post(`${resource}/action/translate-delegate-find`, payload);
    },

    actionTranslateLocaleByProduct(productIds) {
        let payload = new FormData();
        payload.append('product', JSON.stringify(productIds));
        return Repository.post(`${resource}/action/translate-locale`, payload);
    },

    catalog(productIds, categoryId) {
        let payload = new FormData();
        payload.append('product_ids', JSON.stringify(productIds));

        if(!_.isNil(categoryId)) {
            payload.append('category_id', JSON.stringify(categoryId));
        }

        return Repository.post(`${resource}/catalog`, payload);
    },

    position(categoryId, strict) {
        let payload = new FormData();
        payload.append('category_id', JSON.stringify(categoryId));

        if (!_.isNil(strict)) {
            payload.append('_strict', JSON.stringify(strict));
        }

        return Repository.post(`${resource}/position`, payload);
    },

    positionSave(products, categoryId, parentId, auto) {
        let payload = new FormData();
        payload.append('products', JSON.stringify(products));
        payload.append('category_id', JSON.stringify(categoryId));

        if (!_.isNil(parentId)) {
            payload.append('parent_id', JSON.stringify(parentId));
        }

        if (!_.isNil(auto)) {
            payload.append('_auto', JSON.stringify(auto));
        }

        return Repository.post(`${resource}/position-save`, payload);
    },

    reservoir() {
        let payload = new FormData();

        return Repository.post(`${resource}/reservoir`, payload);
    },

    reservoirLimit() {
        let payload = new FormData();

        return Repository.post(`${resource}/reservoir-limit`, payload);
    },

    validation(productId) {
        let payload = new FormData;

        if(!_.isNil(productId)) {
            payload.append('product_id', productId);
        }

        return Repository.post(`${resource}/validation`, payload);
    },

    validationAccept(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationAcceptByType(validationType, productId) {
        let payload = new FormData;
        payload.append('type', validationType);
        payload.append('product_id', productId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationReject(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);
        payload.append('accept', JSON.stringify(false));

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validate(productId) {
        let payload = new FormData;
        payload.append('product_id', productId);

        return Repository.post(`${resource}/validate`, payload);
    },

    clone(payload) {
        return Repository.post(`${resource}/clone`, payload);
    },

    setSearchBoost(productId, searchBoost) {
        let payload = new FormData;
        payload.append('product_id', productId);
        payload.append('search_boost', searchBoost);

        return Repository.post(`${resource}/search-boost`, payload);
    }
}