import Repository from "./Repository";

const resource = "/api/slovakpost";

export default {
    findAllOfficesAndBoxes() {
        return Repository.get(`${resource}/offices-boxes`);
    },

    findAllOffices() {
        return Repository.get(`${resource}/offices`);
    },

    selectOffice(zip, town) {
        let data = new FormData();
        data.append('zip', zip);
        data.append('town', town);

        return Repository.post(`${resource}/select`, data);
    },
}