import Repository from "./Repository";

const resource = "/admin/api/product/code";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    attach(productId, type, supplier, code) {
        let payload = new FormData();
        payload.append('product', productId);
        payload.append('type', type);
        payload.append('supplier', supplier);
        payload.append('code', code);

        return Repository.post(`${resource}/attach`, payload);
    },

    detach(productId, type, supplier, code) {
        let payload = new FormData();
        payload.append('product', productId);
        payload.append('type', type);
        payload.append('code', code);

        if (supplier) {
            payload.append('supplier', supplier.id);
        }

        return Repository.post(`${resource}/detach`, payload);
    },

    exists(code, type) {
        let payload = new FormData();
        payload.append('code', code);
        payload.append('type', type);

        return Repository.post(`${resource}/exists`, payload);
    },

    setSearchable(productId, value, type, with_children) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));
        payload.append('type', JSON.stringify(type));
        payload.append('value', JSON.stringify(value));
        payload.append('with_children', JSON.stringify(with_children));

        return Repository.post(`${resource}/searchable-set`, payload);
    },

    setAttribute(productId, value, type, with_children) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));
        payload.append('type', JSON.stringify(type));
        payload.append('value', JSON.stringify(value));
        payload.append('with_children', JSON.stringify(with_children));

        return Repository.post(`${resource}/attribute-set`, payload);
    },
}