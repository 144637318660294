import Repository from "./Repository";
import Vue from "vue";

const resource = "/api/cart";

export default {
    getData() {
        return Repository.post(`${resource}/data`);
    },

    submit(payload) {
        return Repository.post(`${resource}/submit`, payload);
    },

    emailVerify(input) {
        let payload = new FormData;
        payload.append('input', input);

        return Repository.post(`${resource}/email-verify`, payload);
    },

    findTargetItems(input) {
        let payload = new FormData;

        if(input) {
            payload.append('input', input);
        }

        return Repository.post(`${resource}/target-items`, payload);
    },

    getForm(country) {
        let payload = new FormData;
        payload.append('country', country);

        return Repository.post(`${resource}/form`, payload);
    },

    voucher(code, price) {
        let payload = new FormData;
        payload.append('code', code);
        payload.append('price', price);

        const queryString = new URLSearchParams(payload).toString();

        return Repository.get(`${resource}/voucher?${queryString}`);
    },

    findServices() {
        return Repository.get(`${resource}/service`);
    },

    addService(service) {
        let payload = new FormData;
        payload.append('service_id', service);

        return Repository.post(`${resource}/add-service`, payload);
    },

    addAddress(zip, town) {
        let payload = new FormData;
        payload.append('zip', zip);
        payload.append('town', town);

        return Repository.post(`${resource}/add-address`, payload);
    },

    getFavoriteAddress() {
        return Repository.get(`${resource}/address`);
    },

    charity(items) {
        let payload = new FormData;
        payload.append('items', JSON.stringify(_.map(items, 'name')));

        return Repository.post(`${resource}/charity`, payload);
    },

    charityVerify(id, phone) {
        let payload = new FormData;
        payload.append('id', id);
        payload.append('phone', phone);

        return Repository.post(`${resource}/charity-verify`, payload);
    },

    log(purchaseId, action, note, status) {
        let payload = new FormData();
        payload.append('purchase_id', purchaseId);
        payload.append('action', action);
        payload.append('note', note);

        if(!_.isNil(status)) {
            payload.append('status', status);
        }

        return Repository.post(`${resource}/log`, payload);
    },

    add(productId, quantity) {
        let payload = new FormData();
        payload.append('id', productId);
        payload.append('quantity', quantity);

        return Repository.post(`${resource}/add`, payload);
    },

    update(productId, quantity) {
        let payload = new FormData();
        payload.append('id', productId);
        payload.append('quantity', quantity);

        return Repository.post(`${resource}/update`, payload);
    },

    selectDelivery(id) {
        let payload = new FormData();
        payload.append('company', id);

        return Repository.post(`/api/shipping/select`, payload);
    },

    selectPayment(id) {
        let payload = new FormData();
        payload.append('payment', id);

        return Repository.post(`/api/shipping/select`, payload);
    },

    favorite(id) {
        let payload = new FormData();
        payload.append('id', id);
        payload.append('is', true);

        return Repository.post(`${resource}/favorite`, payload);
    },

    unfavorite(id) {
        let payload = new FormData();
        payload.append('id', id);
        payload.append('is', false);

        return Repository.post(`${resource}/unfavorite`, payload);
    },

    gifts(id) {
        return Repository.get(`${resource}/gifts`);
    },

    selectedGifts() {
        return Repository.get(`${resource}/gift-selected`);
    },

    giftMotivations() {
        return Repository.get(`${resource}/gift-motivations`);
    },

    selectGift(id) {
        let payload = new FormData();
        payload.append('gift_id', id);

        return Repository.post(`/api/gift/select`, payload);
    },
}