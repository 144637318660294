import Repository from "./Repository";

const resource = "/admin/api/map";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    edit(payload) {
        return Repository.post(`${resource}/edit`, payload);
    },

    path(payload) {
        return Repository.post(`${resource}/path`, payload);
    },
}