import Repository from "./Repository";

const resource = "/admin/api/taggroup";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    search() {
        return Repository.post(`${resource}/search`);
    },

    getValue(id) {
        return Repository.get(`${resource}/${id}/search`);
    },
}