import Repository from "./Repository";

const resource = "/api/tracker-finder";

export default {
    find(purchaseId, phone) {
        let payload = new FormData();
        payload.append('purchase', purchaseId);
        payload.append('phone', phone);

        return Repository.post(`${resource}`, payload);
    }
}