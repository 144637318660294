import Repository from "./Repository";

const resource = "/admin/api";

export default {
    change(payload) {
        return Repository.post(`${resource}/change`, payload);
    },

    consent(email) {
        let payload = new FormData();
        payload.append('email', JSON.stringify(email));

        return Repository.post(`${resource}/consent`, payload);
    },

    scanCode(code) {
        let payload = new FormData();
        payload.append('code', code);

        return Repository.post(`${resource}/scan`, payload);
    },

    log(action, note, status) {
        let payload = new FormData();
        payload.append('action', action);
        payload.append('note', note);

        if(!_.isNil(status)) {
            payload.append('status', status);
        }

        return Repository.post(`${resource}/log`, payload);
    },
}