import Repository from "./Repository";

const resource = "/admin/api/purchase";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    list(ids) {
        let payload = new FormData;

        payload.append('ids', JSON.stringify(ids));

        return Repository.post(`${resource}s`, payload);
    },

    filter(criteria, adminSettingId) {
        let payload = new FormData;

        if(!_.isNil(criteria)) {
            payload.append('criteria', criteria);
        }

        if(!_.isNil(adminSettingId)) {
            payload.append('admin_setting_id', adminSettingId);
        }

        // _.forEach(criteria, (value, key) => {
        //     payload.append(key, value);
        // });

        return Repository.post(`${resource}/filter`, payload);
    },

    search(text) {
        let payload = new FormData;

        if(!_.isNil(text)) {
            payload.append('text', text);
        }

        return Repository.post(`${resource}/search`, payload);
    },

    observer(id) {
        let payload = new FormData;
        payload.append('purchase_id', id);

        return Repository.post(`${resource}/observer`, payload);
    },

    checkStock(id) {
        let payload = new FormData;
        payload.append('id', id);

        return Repository.post(`${resource}/stock`, payload);
    },

    findItems(id) {
        return Repository.get(`${resource}/${id}/items`);
    },

    addItem(purchaseId, productId, quantity, price, reserve) {
        let payload = new FormData;
        payload.append('purchase_id', purchaseId);
        payload.append('product_id', productId);
        payload.append('quantity', quantity);
        payload.append('price', price);

        if(!_.isNil(reserve)) {
            payload.append('reserve', JSON.stringify(reserve));
        }

        return Repository.post(`${resource}/item-add`, payload);
    },

    edit(payload) {
        return Repository.post(`${resource}/edit`, payload);
    },

    validation(purchaseId) {
        let payload = new FormData;
        payload.append('purchase_id', purchaseId);

        return Repository.post(`${resource}/validation`, payload);
    },

    validationAccept(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationAcceptByType(validationType, purchaseId) {
        let payload = new FormData;
        payload.append('type', validationType);
        payload.append('purchase_id', purchaseId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationReject(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);
        payload.append('accept', JSON.stringify(false));

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validate(purchaseId) {
        let payload = new FormData;
        payload.append('purchase_id', purchaseId);

        return Repository.post(`${resource}/validate`, payload);
    },

    findValidationTypes() {
        return Repository.get(`${resource}/validation-types`);
    },

    findGiftItems(purchaseId) {
        let payload = new FormData();
        payload.append('purchase_id', JSON.stringify(purchaseId));

        return Repository.post(`${resource}/gift-items`, payload);
    },

    log(purchaseId, action, note, status) {
        let payload = new FormData();
        payload.append('purchase_id', purchaseId);
        payload.append('action', action);
        payload.append('note', note);

        if(!_.isNil(status)) {
            payload.append('status', status);
        }

        return Repository.post(`${resource}/log`, payload);
    },

    creditnote(purchaseId, items, shipping) {
        let payload = new FormData();
        payload.append('purchase', JSON.stringify(purchaseId));
        payload.append('items', JSON.stringify(items));
        payload.append('shipping', JSON.stringify(shipping));

        return Repository.post(`${resource}/creditnote`, payload);
    },

    tracker(purchaseId) {
        let payload = new FormData();
        payload.append('purchase_id', JSON.stringify(purchaseId));

        return Repository.post(`${resource}/tracker`, payload);
    },

    merge(payload) {
        return Repository.post(`${resource}/merge`, payload);
    },
}