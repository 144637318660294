import Repository from "./Repository";

const resource = "/admin/api/product-stock-rack";

export default {
    findAll() {
        return Repository.get(`${resource}s`);
    },

    findBy(payload) {
        return Repository.post(`${resource}s`, payload);
    },

    getProductId(stockRack, box) {
        let payload = new FormData();
        payload.append('stock_rack', stockRack);
        payload.append('box', box);

        return Repository.post(`${resource}/product`, payload);
    },

    getStockRackId(stockRack, box) {
        let payload = new FormData();
        payload.append('stock_rack', stockRack);
        payload.append('box', box);

        return Repository.post(`${resource}/stock-rack`, payload);
    },

    findEmpty(productId, stockRackId, quantity) {
        let payload = new FormData();

        if(!_.isNil(productId)) {
            payload.append('product_id', productId);
        }

        if(!_.isNil(stockRackId)) {
            payload.append('stock_rack_id', stockRackId);
        }

        if(!_.isNil(quantity) && quantity > 0) {
            payload.append('quantity', quantity);
        }

        return Repository.post(`${resource}/empty`, payload);
    },
}