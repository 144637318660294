import Repository from "./Repository";

const resource = "/admin/api/product/price";

export default {
    findAll() {
        return Repository.get(`${resource}s`);
    },

    findBy(payload) {
        return Repository.post(`${resource}/find`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },

    create(payload) {
        return Repository.post(`${resource}`, payload);
    }
}