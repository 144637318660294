import Repository from "./Repository";

const resource = "/admin/api/slack";

export default {
    send(message, channel) {
        let payload = new FormData;
        payload.append('message', message);
        payload.append('channel', channel);

        return Repository.post(`${resource}`, payload);
    },
}