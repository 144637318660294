import Repository from "./Repository";

const resource = "/admin/api/product-image";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    findBy2(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    rename(id, name) {
        let payload = new FormData();
        payload.append('id', id);
        payload.append('name', name);

        return Repository.post(`${resource}/rename`, payload);
    },
}