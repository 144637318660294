import Repository from "./Repository";

const resource = "/admin/api/shipping";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    companyFindAll() {
        return Repository.get(`${resource}-company`);
    },

    paymentFindAll() {
        return Repository.get(`${resource}-payment`);
    },
}