import ProductRepository from "./productRepository";
import ProductPriceRepository from "./productPriceRepository";
import CallRepository from "./callRepository";
import ShopRepository from "./shopRepository";
import StockRepository from "./stockRepository";
import StockRackRepository from "./stockRackRepository";
import StockItemRepository from "./stockItemRepository";
import StockMovementItemRepository from "./stockMovementItemRepository";
import StockReserveRepository from "./stockReserveRepository";
import StockScheduleRepository from "./stockScheduleRepository";
import UnitRepository from "./unitRepository";
import CategoryRepository from "./categoryRepository";
import CatalogRepository from "./catalogRepository";
import CountryRepository from "./countryRepository";
import CurrencyRepository from "./currencyRepository";
import PackageRepository from "./packageRepository";
import SupplyRepository from "./supplyRepository";
import MiscRepository from "./miscRepository";
import PurchaseRepository from "./purchaseRepository";
import InvoiceRepository from "./invoiceRepository";
import ReferenceRateRepository from "./referenceRateRepository";
import SlackRepository from "./slackRepository";
import PackerRepository from "./packerRepository";
import WarehouseRepository from "./warehouseRepository";
import UserRepository from "./userRepository";
import UserTrackerRepository from "./userTrackerRepository";
import ProductImageRepository from "./productImageRepository";
import ProductCodeRepository from "./productCodeRepository";
import SupplierRepository from "./supplierRepository";
import ExportRepository from "./exportRepository";
import WatchdogRepository from "./watchdogRepository";
import AdminWatchdogRepository from "./admin/watchdogRepository";
import TagRepository from "./tagRepository";
import TagGroupRepository from "./tagGroupRepository";
import OrderRepository from "./orderRepository";
import FeedRepository from "./feedRepository";
import ProductVideoRepository from "./productVideoRepository";
import ProductStatRepository from "./productStatRepository";
import SettingsRepository from "./settingsRepository";
import AdminSettingsRepository from "./adminSettingsRepository";
import GiftItemRepository from "./giftItemRepository";
import SlovakPostRepository from "./slovakPostRepository";
import CacheRepository from "./cacheRepository";
import ProductAttributeRepository from "./productAttributeRepository";
import IssueRepository from "./issueRepository";
import ServiceRepository from "./serviceRepository";
import CartRepository from "./cartRepository";
import ClaimRepository from "./claimRepository";
import AccountingRecordRepository from "./accountingRecordRepository";
import BankAccountRepository from "./bankAccountRepository";
import StorageRepository from "./storageRepository";
import ConsentRepository from "./consentRepository";
import ConsumerGroupCatalogRepository from "./consumerGroupCatalogRepository";
import ConsumerGroupRepository from "./consumerGroupRepository";
import CharityRepository from "./charityRepository";
import LocaleRepository from "./localeRepository";
import TrackerFinderRepository from "./trackerFinderRepository";
import ShippingRepository from "./shippingRepository";
import DomainRepository from "./domainRepository";
import DeviceRepository from "./deviceRepository";
import NotificationRepository from "./notificationRepository";
import PriceTrackerRepository from "./priceTrackerRepository";
import ProductStockRackRepository from "./productStockRackRepository";
import BoxRepository from "./boxRepository";
import MapRepository from "./mapRepository";

const repositories = {
    call: CallRepository,
    product: ProductRepository,
    productPrice: ProductPriceRepository,
    shop: ShopRepository,
    stock: StockRepository,
    stockRack: StockRackRepository,
    stockItem: StockItemRepository,
    stockMovementItem: StockMovementItemRepository,
    stockReserve: StockReserveRepository,
    stockSchedule: StockScheduleRepository,
    unit: UnitRepository,
    category: CategoryRepository,
    catalog: CatalogRepository,
    country: CountryRepository,
    currency: CurrencyRepository,
    package: PackageRepository,
    supply: SupplyRepository,
    misc: MiscRepository,
    purchase: PurchaseRepository,
    invoice: InvoiceRepository,
    referenceRate: ReferenceRateRepository,
    slack: SlackRepository,
    packer: PackerRepository,
    warehouse: WarehouseRepository,
    user: UserRepository,
    userTracker: UserTrackerRepository,
    productImage: ProductImageRepository,
    supplier: SupplierRepository,
    productCode: ProductCodeRepository,
    export: ExportRepository,
    watchdog: WatchdogRepository,
    adminWatchdog: AdminWatchdogRepository,
    tag: TagRepository,
    tagGroup: TagGroupRepository,
    order: OrderRepository,
    feed: FeedRepository,
    productVideo: ProductVideoRepository,
    productStat: ProductStatRepository,
    settings: SettingsRepository,
    adminSettings: AdminSettingsRepository,
    giftItem: GiftItemRepository,
    slovakPost: SlovakPostRepository,
    cache: CacheRepository,
    productAttribute: ProductAttributeRepository,
    issue: IssueRepository,
    service: ServiceRepository,
    cart: CartRepository,
    claim: ClaimRepository,
    accountingRecord: AccountingRecordRepository,
    bankAccount: BankAccountRepository,
    storage: StorageRepository,
    consent: ConsentRepository,
    consumerGroupCatalog: ConsumerGroupCatalogRepository,
    consumerGroup: ConsumerGroupRepository,
    charity: CharityRepository,
    locale: LocaleRepository,
    trackerFinder: TrackerFinderRepository,
    shipping: ShippingRepository,
    domain: DomainRepository,
    device: DeviceRepository,
    notification: NotificationRepository,
    priceTracker: PriceTrackerRepository,
    productStockRack: ProductStockRackRepository,
    box: BoxRepository,
    map: MapRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};