import Repository from "./Repository";

const resource = "/admin/api/stock-item";

export default {
    getByProduct(productId, log) {
        let data = new FormData();
        data.append('product_id', productId);
        data.append('_log', log);

        return Repository.post(`${resource}`, data);
    },
}