import axios from 'axios';
import NProgress from 'nprogress/nprogress';
import 'nprogress/nprogress.css';

const instance = axios.create({
    // baseURL: `/admin/api`
});

NProgress.setColor = (color) => {
    const style = document.createElement('style')
    style.textContent = `
        #nprogress .bar {
            background: ${color} !important;
        }
        
        #nprogress .peg {
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
        }
  `
    document.body.appendChild(style)
}

let count = 0;
let hasError = false;

instance.interceptors.request.use(config => {
    if(hasError) {
        return;
    }

    count++;
    NProgress.start();

    return config;
}, error => {
    hasError = true;

    console.error(error);

    NProgress.setColor("red");
    NProgress.configure({ showSpinner: false });
    NProgress.done(true);
    // NProgress.remove();

    throw error;
});

instance.interceptors.response.use(response => {
    if(hasError) {
        return;
    }

    if(--count === 0) {
        NProgress.done();
    }

    return response;
}, error => {
    hasError = true;

    console.error(error.message ? error.message : error);

    NProgress.setColor("red");
    NProgress.configure({ showSpinner: false });
    NProgress.done(true);
    // NProgress.remove();

    throw error;
});

export default instance; // export axios instace to be imported in your app