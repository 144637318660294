import Repository from "./Repository";

const resource = "/admin/api/product/attribute";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    save(payload) {
        return Repository.post(`${resource}/save`, payload);
    },

    findTypes() {
        return Repository.get(`${resource}/types`);
    }
}