import Repository from "../Repository";

const resource = "/admin/api/watchdog";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    save(payload) {
        return Repository.post(`${resource}`, payload);
    },

    remove(watchdogId) {
        return Repository.get(`${resource}/${watchdogId}/remove`);
    },
}