import Repository from "./Repository";

const resource = "/admin/api/feed";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    attach(productId, categoryId, imageId, name, cpc) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));
        payload.append('category_id', JSON.stringify(categoryId));
        payload.append('image_id', JSON.stringify(imageId));
        payload.append('name', JSON.stringify(name));
        payload.append('cpc', JSON.stringify(cpc));

        return Repository.post(`${resource}/attach`, payload);
    },

    edit(itemId, productId, categoryId, imageId, name, cpc) {
        let payload = new FormData();
        payload.append('item_id', JSON.stringify(itemId));
        payload.append('product_id', JSON.stringify(productId));
        payload.append('category_id', JSON.stringify(categoryId));
        payload.append('image_id', JSON.stringify(imageId));
        payload.append('name', JSON.stringify(name));
        payload.append('cpc', JSON.stringify(cpc));

        return Repository.post(`${resource}/edit`, payload);
    },

    createOrUpdate(itemId, productId, categoryId, imageId, name, cpc) {
        if(_.isNil(itemId)) {
            return this.attach(productId, categoryId, imageId, name, cpc);
        } else {
            return this.edit(itemId, productId, categoryId, imageId, name, cpc);
        }
    },

    categories(type) {
        let payload = new FormData();
        payload.append('type', type);

        return Repository.post(`${resource}/categories`, payload);
    },
}