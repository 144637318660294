import Repository from "./Repository";

const resource = "/admin/api/stock-movement-item";

export default {
    findByStockRack(stockRackId, isAdvanced) {
        let data = new FormData();
        data.append('stock_rack_id', stockRackId);
        data.append('advanced', isAdvanced);

        return Repository.post(`${resource}`, data);
    },

    findByProduct(productId, stockId, isAdvanced, type, keys) {
        let data = new FormData();
        data.append('product_id', productId);
        data.append('stock_id', stockId);
        data.append('advanced', isAdvanced);
        data.append('type', type);

        if (!_.isNil(keys)) {
            data.append('keys', keys);
        }

        return Repository.post(`${resource}`, data);
    },

    findByAction(action) {
        let data = new FormData();
        data.append('action', action);

        return Repository.post(`${resource}`, data);
    },
}