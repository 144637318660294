import Repository from "./Repository";

const resource = "/admin/api/supplier";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },

    findByProduct(productId) {
        let data = new FormData();
        data.append('product_id', productId);

        return Repository.post(`/admin/api/product/supplier`, data);
    }
}