import Repository from "./Repository";

const resource = "/admin/api/supply";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    waiting(serializeGroup) {
        let payload = new FormData();
        payload.append('serialize_group', serializeGroup);

        return Repository.post(`${resource}/waiting`, payload);
    },

    item(id) {
        let payload = new FormData();
        payload.append('id', JSON.stringify(id));

        return Repository.post(`${resource}/item`, payload);
    },

    items(id) {
        let payload = new FormData();
        payload.append('supply_id', id);

        return Repository.post(`${resource}/items`, payload);
    },

    notes(id) {
        let payload = new FormData();
        payload.append('supply_id', id);

        return Repository.post(`${resource}/notes`, payload);
    },

    supplier(id) {
        let payload = new FormData();
        payload.append('supply_id', id);

        return Repository.post(`${resource}/supplier`, payload);
    },

    source(productId, supplierId) {
        let payload = new FormData();
        payload.append('product_id', productId);
        payload.append('supplier_id', supplierId);

        return Repository.post(`${resource}/source`, payload);
    },

    control(id, items, extra) {
        let payload = new FormData();
        payload.append('id', JSON.stringify(id));
        payload.append('items', JSON.stringify(items));

        if(!_.isNil(extra)) {
            payload.append('extra', extra);
        }

        return Repository.post(`${resource}/control`, payload);
    },

    controlAdd(id, counted, stockRackId) {
        let payload = new FormData();
        payload.append('id', JSON.stringify(id));
        payload.append('quantity', JSON.stringify(counted));

        if (!_.isNil(stockRackId)) {
            payload.append('stock_rack', JSON.stringify(stockRackId));
        }

        return Repository.post(`${resource}/control-add`, payload);
    },

    validation(supplyId) {
        let payload = new FormData;
        payload.append('supply_id', supplyId);

        return Repository.post(`${resource}/validation`, payload);
    },

    validationAccept(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationAcceptByType(validationType, supplyId) {
        let payload = new FormData;
        payload.append('type', validationType);
        payload.append('supplye_id', supplyId);

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validationReject(validationId) {
        let payload = new FormData;
        payload.append('id', validationId);
        payload.append('accept', JSON.stringify(false));

        return Repository.post(`${resource}/validation-accept`, payload);
    },

    validate(supplyId) {
        let payload = new FormData;
        payload.append('supply_id', supplyId);

        return Repository.post(`${resource}/validate`, payload);
    },

    print(productId, quantity) {
        let payload = new FormData;
        payload.append('product_id', JSON.stringify(productId));
        payload.append('quantity', JSON.stringify(quantity));

        return Repository.post(`${resource}/print`, payload);
    },
}