import Repository from "./Repository";

const resource = "/admin/api/call";

export default {
    getAll() {
        return Repository.get(`${resource}`);
    },

    getOne(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create() {

    },

    remove(id) {
        return Repository.get(`${resource}/${id}/remove`);
    },

    seen(id) {
        return Repository.get(`${resource}/${id}/seen`);
    }
}