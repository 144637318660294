import Repository from "./Repository";

const resource = "/admin/api/device";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    finishedPurchaseCount(deviceId) {
        let payload = new FormData;
        payload.append('device_id', JSON.stringify(deviceId));

        return Repository.post(`${resource}/finished-purchase-count`, payload);
    },
}