import Repository from "./Repository";

const resource = "/admin/api/product/stat";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    merchantability30days(payload) {
        return Repository.post(`/admin/api/merchantability`, payload);
    },
}