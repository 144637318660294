import Repository from "./Repository";

const resource = "/admin/api/order";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload)
    },

    findTrend() {
        return Repository.get(`${resource}`);
    },
}