import Repository from "./Repository";

const resource = "/api/consent";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },

    save(type, settings) {
        let payload = new FormData();
        payload.append('settings', JSON.stringify(settings));

        return Repository.post(`${resource}/${type}`, payload)
    },
}