import Repository from "./Repository";

const resource = "/admin/api/gift-item";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`)
    },

    create(payload) {
        return Repository.post(`${resource}`, payload);
    },

    // findBy(payload) {
    //     return Repository.post(`${resource}/find`, payload);
    // },

    findByPurchaseFrequency(locale) {
        let payload = new FormData();
        payload.append('locale_id', JSON.stringify(locale));

        return Repository.post(`${resource}/frequently`, payload);
    },

    findByPurchase(purchaseId) {
        let payload = new FormData();
        payload.append('purchase_id', JSON.stringify(purchaseId));

        return Repository.post(`${resource}/frequently`, payload);
    },

    attach(purchaseId, giftItemId) {
        return Repository.get(`/admin/api/purchase-gift/attach/${purchaseId}/${giftItemId}`);
    },

    detach(purchaseId, giftItemId) {
        return Repository.get(`/admin/api/purchase-gift/detach/${purchaseId}/${giftItemId}`);
    },
}