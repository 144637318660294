import Repository from "./Repository";

const resource = "/admin/api/consumer-group";

export default {
    getAll() {
        return Repository.get(`${resource}`);
    },

    getOne(id) {
        return Repository.get(`${resource}/${id}`);
    },
}