import _ from 'lodash';
import Repository from "./Repository";

const resource = "/admin/api/user-tracker";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    actions(status, admin) {
        let params = '?';

        if (status) {
            params += 'status=' + status;
        }

        if (admin) {
            params += 'admin=' + admin;
        }

        return Repository.get(`${resource}/actions` + params);
    },

    tags() {
        return Repository.get(`${resource}/tags`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    edit(payload) {
        return Repository.post(`${resource}/edit`, payload);
    },

    start(action, value, parent, note) {
        let payload = new FormData();
        payload.append('action', action);
        payload.append('date', 'start');

        if (value) {
            payload.append('value', value);
        }

        if (parent) {
            payload.append('parent', parent);
        }

        if (note) {
            payload.append('note', note);
        }

        return Repository.post(`${resource}`, payload);
    },

    finish(action, id, value, note, norm, task, tag) {
        let payload = new FormData();
        payload.append('action', action);
        payload.append('id', id);
        payload.append('norm', norm);
        payload.append('task', task);
        payload.append('tag', tag);
        payload.append('date', 'finish');

        if (value) {
            payload.append('value', value);
        }

        if (note) {
            payload.append('note', note);
        }

        return Repository.post(`${resource}`, payload);
    },

    data(actions, dateFrom, dateTo, users, fullDay, overflow) {
        let payload = new FormData();
        payload.append('users', JSON.stringify(users));
        payload.append('actions', JSON.stringify(actions));
        payload.append('date_from', dateFrom);
        payload.append('date_to', dateTo);

        if(!_.isNil(fullDay)) {
            payload.append('full_day', JSON.stringify(fullDay));
        }

        if(!_.isNil(overflow)) {
            payload.append('overflow', JSON.stringify(overflow));
        }

        return Repository.post(`${resource}/data`, payload);
    },

    norm(action) {
        let payload = new FormData();
        payload.append('action', action);

        return Repository.post(`${resource}/norm`, payload);
    },

    task() {
        let payload = new FormData();

        return Repository.post(`${resource}/task`, payload);
    },

    update(id, from, to) {
        let payload = new FormData();
        payload.append('id', JSON.stringify(id));
        payload.append('from', JSON.stringify(from));
        payload.append('to', JSON.stringify(to));

        return Repository.post(`${resource}/update`, payload);
    }
}