import Repository from "./Repository";

const resource = "/admin/api/storage";

export default {
    findOnload(count) {
        let payload = new FormData();
        payload.append('count', JSON.stringify(count));

        return Repository.post(`${resource}/onload`, payload);
    },

    blockOnload(productId) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));

        return Repository.post(`${resource}/block-onload`, payload);
    },
}