import Repository from "./Repository";

const resource = "/admin/api/packer";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(payload) {
        return Repository.post(`${resource}/create`, payload);
    },

    finish(purchaseId, weight, force, message) {
        let payload = new FormData();
        payload.append('purchase', JSON.stringify(purchaseId));
        payload.append('weight', JSON.stringify(weight));
        payload.append('force', JSON.stringify(force));

        if(message) {
            payload.append('message', JSON.stringify(message));
        }

        return Repository.post(`${resource}/finish`, payload);
    },

    findItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`${resource}/items`, payload);
    },

    findSetItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`/admin/api/warehouse/set-items`, payload);
    },

    findGiftItems(purchases) {
        let payload = new FormData();
        payload.append('purchases', JSON.stringify(purchases));

        return Repository.post(`/admin/api/warehouse/gift-items`, payload);
    },

    findPurchases() {
        return Repository.post(`${resource}/purchases`);
    },

    findPurchasesNew() {
        return Repository.post(`${resource}/purchases-new`);
    },

    setCode(purchaseId, code) {
        let payload = new FormData();
        payload.append('purchase', JSON.stringify(purchaseId));
        payload.append('code', JSON.stringify(code));

        return Repository.post(`${resource}/code`, payload);
    },
}