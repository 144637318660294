import Repository from "./Repository";

const resource = "/admin/api/product/video";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    attach(payload) {
        return Repository.post(`${resource}/attach`, payload);
    },
}