import Repository from "./Repository";

const resource = "/admin/api/stock";

export default {
    findAll() {
        return Repository.post(`${resource}`);
    },

    move(payload) {
        return Repository.post(`${resource}/move`, payload);
    },

    history(productId, dateFrom, dateTo, type, invoice) {
        let payload = new FormData();
        payload.append('product_id', productId);

        if(dateFrom) {
            payload.append('date_from', dateFrom);
        }

        if(dateTo) {
            payload.append('date_to', dateTo);
        }

        if(type) {
            payload.append('type', type);
        }

        if(invoice) {
            payload.append('invoice', invoice);
        }

        return Repository.post(`${resource}/history`, payload);
    },
}