import Repository from "./Repository";

const resource = "/admin/api/invoice";

export default {
    getAll() {
        return Repository.get(`${resource}`);
    },

    getOne(id) {
        return Repository.get(`${resource}/${id}`);
    },

    findItems(id) {
        return Repository.get(`${resource}/${id}/items`);
    },
}