import Repository from "./Repository";

const resource = "/admin/api/stock-rack";

export default {
    find(id) {
        return Repository.get(`${resource}/${id}`);
    },

    findAll() {
        return Repository.post(`${resource}`);
    },

    findBy(criteria, productId) {
        let payload = new FormData();
        payload.append('criteria', JSON.stringify(criteria));

        if(productId) {
            payload.append('product_id', productId);
        }

        return Repository.post(`${resource}`, payload);
    },

    findSegments() {
        return Repository.get(`${resource}/segments`);
    },

    search(query, criteria) {
        let payload = new FormData();
        payload.append('query', query);
        payload.append('criteria', JSON.stringify(criteria));
        payload.append('_elastic', JSON.stringify(false));

        return Repository.post(`${resource}/search`, payload);
    },
}