import Repository from "./Repository";

const resource = "/admin/api/notification";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    create(template, parameters) {
        let payload = new FormData();
        payload.append('template', template);
        payload.append('parameters', JSON.stringify(parameters));

        return Repository.post(`${resource}/create`, payload);
    },
}